import * as signalR from '@microsoft/signalr'
import ENV from '@/config/environment'
import router from '@/router'

function extractFilename(contentDisposition) {
    if (!contentDisposition) return null;
    const matches = contentDisposition.match(/filename="(.*?)"/);
    return matches ? matches[1] : null;
}

function initialState() {
  return {
    connected: false,
    attempt: 0,
    closeConnection: false,
    socketRetryTimer: [0, 5, 10, 15, 30],
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    connectToSocket: function ({ commit, dispatch, rootState }) {
        let that = this;
        let attempt = 0;
        const apiPath1 = ENV.env_api.slice(0, -4);
        const options = {
          accessTokenFactory: () => sessionStorage.getItem("token"),
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        };

        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${apiPath1}hubs/parequest`, options)
          .build();

        commit('SET_SOCKET_CLOSE', () => connection.stop())

        connection.on("DataExportPaRequestFinished", async (data) => {
            if(data.paPersonId == rootState.currUser.paPersonId) {
                const id = parseInt(data.id);
                const filename = data.filename;
                if (data.error === null) {
                    dispatch('downloadQueue/DOWNLOAD_BG_EXPORT_SUCCEEDED', {id, filename}, {root: true});
                } else {
                    dispatch('downloadQueue/DOWNLOAD_BG_EXPORT_FAILED', id, {root: true});
                }
            }
        });

        connection.on("ReportFinished", async (data) => {
            if(data.paPersonId == rootState.currUser.paPersonId) {
                if( data.error === false ) {
                    dispatch('reporting/BG_REPORT_FINISHED', data.reportPayload, {root: true});
                } else {
                    commit('reporting/SET_REPORT_BG_ERROR', parseInt(data.reportPayload.reportResultsId), {root: true});
                }
                commit('reporting/SET_REPORT_RUNNING', false, {root: true});
            }
        });

        connection.on("PaRequestUpdated", async (data) => {
          if (router.currentRoute.name === 'requestView') {
            const isViewingRequest = parseInt(router.currentRoute.params.requestId) === parseInt(data.paRequestId);

            if (isViewingRequest) {
              await dispatch("POST_PA_REQUEST_OVERVIEW", 
              {
                paRequestId: data.model.paRequestId,
                isArchive: data.model.status >= 200 || data.model.status === 11

              }, { root:true });
            }
          } else {
            dispatch('UPDATE_SINGLE_REQUEST', data, { root: true })
          }

          if (
            rootState.currUser.paPersonId === data.model.assignedToId &&
            data.model.lastModifiedBy !== rootState.currUser.paPersonId
          ) {
            let dob = removeTime(data.model.dob);
            dispatch("ADD_USER_NOTIFICATION", {
              message: "PA Request Updated",
              destination: `request/${data.paRequestId}`,
              title: `${data.model.lastName} ${dob} - MRN:${data.model.mrn}`,
              when: Date.now(),
            }, { root: true });
          }
        });

        connection.on("SubmissionsUpdated", (data) => {
                    const submission = data.submissionsForPaRequest.find(
            (s) => s.submissionId === data.submissionId
          );
          const [lastHistoryEvent] = data.historyForSubmission.slice(-1);

          if (router.currentRoute.name === 'requestView') {
            const isViewingRequest = parseInt(router.currentRoute.params.requestId) === parseInt(data.paRequestId);

            if (isViewingRequest) {
              dispatch("UPDATE_PA_SUBMISSION", data, { root: true });


              dispatch("POST_PA_REQUEST_OVERVIEW", 
                {
                  paRequestId: data.paRequestId,
                  isArchive:data.status >= 200 || data.status === 11

                }, {root:true});
            }
          }

          if (rootState.currUser.paPersonId === submission.paPersonId) {
            dispatch("ADD_USER_NOTIFICATION", {
              message: `Submission Updated`,
              destination: `request/${data.paRequestId}?tab=submission`,
              title: `${lastHistoryEvent.text}`,
              when: Date.now(),
            }, { root: true });
          }
        });

        // listen for activity updates from olive
        connection.on("ActivityLogUpdated", (data) => {
          if (router.currentRoute.name === "requestView") {
            const isViewingRequest =
              parseInt(router.currentRoute.params.requestId) ===
              parseInt(data.paRequestId);

            if (isViewingRequest) {
              dispatch(
                "GET_EVENT_FEED",
                data.paRequestId,
                { root: true }
              );
            }
          }
        });

        connection.onclose(async (err) => {
          await start();
          if (err) {
              window.console.log(err);
              dispatch("LOG_ERROR", {
                  messageType: "Warn",
                  message: "SignalR Error Occurred",
              }, { root: true });
              dispatch("LOG_ERROR", {
                  messageType: "Error",
                  message: err && err.message ? err.message : err,
              }, { root: true });
          }
          commit("SET_FALLBACK_TO_POLLING", true, { root: true });
        });

        async function start() {

          if (rootState.auth.loggedIn === false) {
            return;
          }
          if (attempt < rootState.socketConnection.socketRetryTimer.length) {
            setTimeout(
              () => startSockectConnection(),
              rootState.socketConnection.socketRetryTimer[attempt] * 1000
            );
            attempt++;
          }
        }

        function startSockectConnection() {
          connection
            .start()
            .then(() => {
              attempt = 0;
              commit("SET_FALLBACK_TO_POLLING", false, { root: true });
            })
            .catch(async (err) => {
              await start();
              dispatch("LOG_ERROR", {
                messageType: "Warn",
                message: "SignalR Error Occurred",
              }, { root: true });
              dispatch("LOG_ERROR", {
                messageType: "Error",
                message: err && err.message ? err.message : err,
              }, { root: true });
              commit("SET_FALLBACK_TO_POLLING", true, { root: true });
            });
        }

        function removeTime (time) {
            if (time) {
              time = time.split(" ");
              return time[0];
            }
            return "";
        }

        start();
    },
    RESET: ({ commit }) => {
      commit('RESET')
    }
  },
  mutations: {
    SET_SOCKET_CLOSE: (state, connection) => {
      state.closeConnection = connection
    },
    RESET: (state) => {
      if (state.closeConnection) {
        state.closeConnection()
      }
      const s = initialState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    },
  },
  getters: {},
}
